import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  isPlatform,
} from "@ionic/react";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

import { radioButtonOffOutline, arrowForwardOutline } from "ionicons/icons";

import { useAuth } from "../hooks/auth";

interface AppPage {
  url: string;
  title: string;
}

// Doctor
const doctorPages: AppPage[] = [
  {
    title: "Prescrição",
    url: "/doctor/prescription",
  },
  {
    title: "Procedimentos",
    url: "/doctor/procedures",
  },
  {
    title: "Em Tratamento",
    url: "/doctor/treatment",
  },
  {
    title: "Contr. de Pacientes",
    url: "/doctor/patients_control",
  },
  {
    title: "Atendimentos",
    url: "/doctor/assistances",
  },
  {
    title: "Cateter (Avulso)",
    url: "/doctor/catheter",
  },
  {
    title: "Abrir OS",
    url: "/machine_orders",
  },
  {
    title: "Dados do Médico",
    url: "/doctor",
  },
  {
    title: "Alterar Senha",
    url: "/password",
  },
];

// Nurse
const nursePages: AppPage[] = [
  {
    title: "Atendimentos",
    url: "/nurse/assistances",
  },
  {
    title: "Agendados",
    url: "/nurse/scheduled",
  },
  {
    title: "Abrir OS",
    url: "/machine_orders",
  },
  {
    title: "Dados do Usuário",
    url: "/user",
  },
  {
    title: "Alterar Senha",
    url: "/password",
  },
];

// Technician
const technicianPages: AppPage[] = [
  {
    title: "Agendados",
    url: "/technician/scheduled_assists",
  },
  {
    title: "Em Andamento",
    url: "/technician/assists_in_progress",
  },
  {
    title: "Atendimentos",
    url: "/technician/assistances",
  },
  {
    title: "Assumir Atendimentos",
    url: "/technician/assistances_take_on",
  },
  {
    title: "Pontuação",
    url: "/technician/score",
  },
  {
    title: "Abrir OS",
    url: "/machine_orders",
  },
  {
    title: "Alterar Senha",
    url: "/password",
  },
];

// Administrative
const administrativePages: AppPage[] = [
  {
    title: "Atendimentos",
    url: "/administrative/assistances",
  },
  {
    title: "Em Andamento",
    url: "/administrative/assistances/progress",
  },
  {
    title: "Indicadores Diálise",
    url: "/administrative/indicators",
  },
  {
    title: "Indicadores p/ Hospital",
    url: "/administrative/indicators/hospital",
  },
  {
    title: "Dados do Usuário",
    url: "/user",
  },
  {
    title: "Alterar Senha",
    url: "/password",
  },
];

// Driver
const driverPages: AppPage[] = [
  {
    title: "Máquinas",
    url: "/driver/machines",
  },
  {
    title: "Dados do Usuário",
    url: "/user",
  },
  {
    title: "Alterar Senha",
    url: "/password",
  },
];

const appVersion = process.env.REACT_APP_VERSION;

const Menu: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();

  const platform = isPlatform("ios");

  const [items, setItems] = useState<AppPage[]>([]);

  useEffect(() => {
    const platformOrientation =
      isPlatform("mobileweb") || isPlatform("desktop");
    if (!platformOrientation) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }

    if (user.profile === "doctor") {
      setItems(doctorPages);
    } else if (user.profile === "nurse") {
      setItems(nursePages);
    } else if (user.profile === "technician") {
      setItems(technicianPages);
    } else if (user.profile === "administrative") {
      setItems(administrativePages);
    } else if (user.profile === "driver") {
      setItems(driverPages);
    }
  }, [user]);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList
          id="inbox-list"
          style={{ marginTop: `${isPlatform("ios") ? "50px" : "5px"}` }}
        >
          {items.map((appPage, index) => {
            if (platform && appPage.title === "Prontuário") {
              return false;
            }
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  style={{
                    "--padding-start": "10px",
                    "--padding-end": "10px",
                    padding: "2px",
                    "--background": `${
                      location.pathname === appPage.url
                        ? "rgba(var(--ion-menu-selected-color-rgb), 0.14)"
                        : "transparent"
                    }`,
                  }}
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    icon={radioButtonOffOutline}
                    style={{
                      color: `${
                        location.pathname === appPage.url
                          ? "var(--ion-icon-color)"
                          : "#000"
                      }`,
                    }}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem
            onClick={signOut}
            lines="none"
            detail={false}
            style={{
              "--padding-start": "10px",
              "--padding-end": "10px",
              padding: "2px",
            }}
          >
            <IonIcon
              slot="start"
              icon={arrowForwardOutline}
              style={{ color: "#000" }}
            />
            <IonLabel>Sair</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter
        style={{
          padding: "40px 20px",
          textAlign: "right",
        }}
        mode="ios"
      >
        <IonHeader
          style={{
            fontSize: "22px",
            fontWeight: 600,
            minHeight: "20px",
          }}
        >
          {`${user.name?.toUpperCase() ? user.name?.toUpperCase() : ""}`}
        </IonHeader>
        <IonNote
          style={{
            fontSize: "18px",
            marginBottom: "18px",
            color: "#757575",
            minHeight: "26px",
          }}
        >
          UTN Nefrologia <br />
          Versão: {appVersion}
        </IonNote>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
